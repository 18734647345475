import React, { useEffect, useState } from 'react'
import { Button, Card, Form } from 'react-bootstrap'
import { Link, withRouter } from 'react-router-dom'

import { orderBy } from 'lodash'
import Moment from 'moment'

import createFormHelpers from '../../FormHelpers'
import CasesService from '../../services/CasesService'
import UserService from '../../services/UserService'
import { CASE_ADMIN_ROLE_NAME, ROLE_NAMES } from '../../utils/Constants'
import AppointmentDashboardCard from '../AppointmentDashboardCard'
import CaseSummary from '../CaseSummary'
import { BackButton } from '../controls/BackButton'
import CaseCloseButton from '../controls/CaseCloseButton'
import FAIcon from '../FAIcon'
import LoadingSpinner from '../LoadingSpinner'
import TaskCard from '../TaskCard'

import './OverviewPage.scss'

const formatDate = date => {
  return !!date && date !== '--' ? Moment(date).format('MM/DD/YYYY') : '--'
}

const OverviewPage = withRouter(
  ({ caseNumber, incompleteAppointments, incompleteTasks, settings, showError, history, completeTasks, user }) => {
    const [caseModel, setCaseModel] = useState()
    const [caseSummaryModel, setCaseSummaryModel] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [users, setUsers] = useState([])
    const [caseMembers, setCaseMembers] = useState([])

    useEffect(() => {
      const casePromise = CasesService.get(caseNumber).then(caseObj => {
        setCaseModel(caseObj)
        setCaseMembers(caseObj.caseMembers)
        return caseObj
      })

      const caseSummaryModel = CasesService.getSummary(caseNumber).then(r => {
        setCaseSummaryModel(r)
      })

      Promise.all([casePromise, caseSummaryModel])
        .then(() => {
          setIsLoading(false)
        })
        .catch(err => {
          showError(err.message)
          setIsLoading(false)
        })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [caseNumber, settings])

    useEffect(() => {
      if (user.roles.includes(CASE_ADMIN_ROLE_NAME)) {
        ;(async () => {
          try {
            const users = await UserService.getAll()
            setUsers(users)
          } catch {}
        })()
      }
    }, [setUsers, user])

    if (isLoading) return <LoadingSpinner />

    const { getValue } = createFormHelpers({})

    const getFormattedDateTimeValue = (startLocal, endLocal) => {
      if (!!startLocal && !!endLocal) {
        const date = Moment(startLocal).format('dddd, MMMM DD, YYYY')
        const startTime = Moment(startLocal).format('h:mma')
        const endTime = Moment(endLocal).format('h:mma')

        return (
          <p className="text-details mb-0">
            {date} &#8226; {startTime}-{endTime}
          </p>
        )
      } else {
        return ''
      }
    }

    const convertUtcToLocalDateTime = utcDateTime => {
      return utcDateTime ? Moment.utc(utcDateTime).local().format() : null
    }

    const handleButtonClick = path => {
      history.push(path)
    }

    const handleAddUser = async e => {
      const userId = e.target?.value
      if (userId) {
        const users = await CasesService.addMembers(caseModel.caseNumber, [userId])
        setCaseMembers(current => [...current, ...users])
      }
    }

    const handleRemoveUser = async userId => {
      if (userId) {
        const result = await CasesService.removeMembers(caseModel.caseNumber, [userId])
        setCaseMembers(current => [...current.filter(c => !result.some(r => r.id === c.id))])
      }
    }

    const appointments = orderBy(incompleteAppointments, x => x.startUtc)
    const displayedAppointments = appointments.slice(0, 6)

    const handleCaseClose = async () => {
      await CasesService.closeCase(caseNumber)
      history.push('/cases')
    }

    return (
      <div className="container overview-page-container pt-5 px-4">
        <div className="pb-4">
          <BackButton />
          <CaseCloseButton handleCaseClose={handleCaseClose} />
        </div>
        <h4>{`${getValue('client.firstName', '', caseModel)} ${getValue('client.lastName', '', caseModel)}`}</h4>
        <div className="row">
          <div className="col-lg-6 col-md-6 mb-4">
            <div className="form-row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label" htmlFor="dateOfInjury">
                    DATE OF INJURY
                  </label>
                  <h6 id="dateOfInjury">{formatDate(getValue('injury.date', '', caseModel))}</h6>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label" htmlFor="dateOfBirth">
                    DATE OF BIRTH
                  </label>
                  <h6 id="dateOfBirth">{formatDate(getValue('client.birthdate', '', caseModel))}</h6>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="caseAdminNotes">
                ADMIN CASE NOTES
              </label>
              <p id="caseAdminNotes">{caseModel.detailsForCaseManager}</p>
            </div>
            <Form.Group>
              <Form.Label className="form-label">CASE MEMBERS</Form.Label>
              <ul style={{ paddingInlineStart: 0 }}>
                {caseMembers?.map(cm => (
                  <li key={cm.id} className="d-flex justify-content-between ">
                    {cm.firstName} {cm.lastName}{' '}
                    {cm.roles?.length > 0 && (
                      <>
                        [
                        {ROLE_NAMES.filter(rn => cm.roles?.includes(rn.key))
                          .map(rn => rn.label)
                          .join(', ')}
                        ]
                      </>
                    )}
                    {user.roles.includes(CASE_ADMIN_ROLE_NAME) === true && (
                      <Button type="button" size="sm" className="ml-1" onClick={() => handleRemoveUser(cm.id)}>
                        ×
                      </Button>
                    )}
                  </li>
                ))}
              </ul>
              {user.roles.includes(CASE_ADMIN_ROLE_NAME) === true && (
                <Form.Control as="select" onChange={handleAddUser}>
                  <option value={undefined}></option>
                  {users
                    .filter(u => !caseMembers?.some(cm => cm.id === u.id))
                    .map(user => (
                      <option key={user.id} value={user.id}>
                        {user.firstName} {user.lastName}
                      </option>
                    ))}
                </Form.Control>
              )}
            </Form.Group>
          </div>
          <div className="col-lg-4 col-md-5 mb-4 offset-lg-2 offset-md-1">
            <div className="d-flex justify-content-between">
              <h6>Next Progress Report</h6>
              <Link className="text-uppercase text-right" to={`/cases/${caseNumber}/reports`}>
                File Report
              </Link>
            </div>
            <CaseSummary
              caseSummaryModel={caseSummaryModel}
              reportHoursTarget={settings.reportHoursTarget}
              reportDaysTarget={settings.reportDaysTarget}
            />
          </div>
        </div>
        <hr className="mt-0 mb-4" />
        <div className="dashboard-container container-fluid">
          <div className="row">
            <div className="appointments-overview-container mt-3 mb-4 col-lg-8 col-md-7 col-sm-12">
              <div className="col-md-12 px-0">
                <div className="mb-3">
                  <FAIcon name="calendar-day" className="fa-lg d-inline-block text-primary" />
                  <h5 className="d-inline-block mb-0 ml-3">{`Appointments ${
                    appointments.length !== displayedAppointments.length
                      ? `(${displayedAppointments.length} of ${appointments.length})`
                      : ''
                  }`}</h5>
                </div>
                <div className="row">
                  {!!displayedAppointments && displayedAppointments.length !== 0 ? (
                    displayedAppointments.map((appointment, index) => {
                      return (
                        <AppointmentDashboardCard
                          key={`appointment-card-${index}`}
                          appointment={appointment}
                          getValue={getValue}
                          getFormattedDateTimeValue={getFormattedDateTimeValue}
                          convertUtcToLocalDateTime={convertUtcToLocalDateTime}
                          handleButtonClick={handleButtonClick}
                        />
                      )
                    })
                  ) : (
                    <div className="col-md-12">
                      <Card className="col-12 mb-3 shadow">
                        <Card.Body>
                          <p className="text-center">
                            <strong>No upcoming or recent appointments to complete</strong>
                          </p>
                          <Link className="btn btn-secondary btn-block" to={`/cases/${caseNumber}/appointments`}>
                            Add an Appointment
                          </Link>
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="tasks-overview-container mt-3 mb-4 col-lg-4 px-0 col-md-5 col-sm-12">
              <div className="mb-3">
                <FAIcon name="calendar-check" type="fas" className="fa-lg d-inline-block text-primary" />
                <h5 className="d-inline-block mb-0 ml-3">Tasks</h5>
              </div>
              <TaskCard
                incompleteTasks={incompleteTasks}
                completeTasks={completeTasks}
                handleButtonClick={handleButtonClick}
                getValue={getValue}
                caseNumber={caseNumber}
              />
            </div>
          </div>
        </div>
      </div>
    )
  },
)

export default OverviewPage
