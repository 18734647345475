import React from 'react'
import { Button, Nav, Navbar } from 'react-bootstrap'

import FAIcon from './FAIcon'

import './TopNav.scss'

const TopNav = props => (
  <Navbar className="component-TopNav" variant="dark" fixed="top">
    <div className="container">
      {!!props.currentUser && Object.keys(props.currentUser).length !== 0 && props.currentUser.roles !== null && (
        <React.Fragment>
          <Nav>
            <Nav.Item>
              <Nav.Link>
                <FAIcon name="user-circle fa-lg" />
              </Nav.Link>
            </Nav.Item>
          </Nav>
          {props.title && <h6 className="navbar-header">{props.title}</h6>}
          <Nav>
            <Nav.Item>
              <Nav.Link>
                <FAIcon name="bell" />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link>
                <FAIcon name="search" />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Button onClick={props.handleLogout} className="btn-primary ml-4">
                Log out
              </Button>
            </Nav.Item>
          </Nav>
        </React.Fragment>
      )}
    </div>
  </Navbar>
)

export default TopNav
